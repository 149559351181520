/** @jsx jsx */
import { useContext } from "react"
import { jsx, Button } from 'theme-ui'

import { SiteContext } from '../../context'
import "../../styles/modal.css"

export const ToggleSignUp = ({cta}) => {
  const { website: {showDrawer}, toggleDrawer } = useContext(SiteContext)

  return(
      <Button onClick={(e) => toggleDrawer(e, showDrawer)} >{cta}</Button>
  )
}