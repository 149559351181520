/** @jsx jsx */
import { jsx, Container, Styled, Flex, Box, Grid } from 'theme-ui'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import { ToggleSignUp } from "../components/Actions"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Comparison } from "../components/Sections"

import mensfitness from '../images/mensfitness.svg'


const IndexPage = () => {


  const data = useStaticQuery(graphql`
  query {  
    logo: file(relativePath: {eq: "fc-stacked-black.png"}){
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    phone: file(relativePath: {eq: "black-phone-app.png"}){
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      } 
    } 
    stevecook: file(relativePath: {eq: "stevecook.png"}){
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      } 
    } 
    kat: file(relativePath: {eq: "kat.png"}){
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      } 
    } 
    tyler: file(relativePath: {eq: "tyler.png"}){
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      } 
    } 
    brock: file(relativePath: {eq: "brock.png"}){
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      } 
    } 
    bodyweight: file(relativePath: {eq: "bodyweight-sc.png"}){
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      } 
    } 
    workout: file(relativePath: {eq: "workout1.png"}){
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      } 
    } 
  } 
`)
  return(
    <Layout>
      <SEO title="Home" />
      <Container sx={{width: "100%", maxWidth: "max", py: 6, px: [5,5,0]}}>
        <Flex sx={{justifyContent: "center", flexWrap: "wrap", alignItems: "center"}}>
          <div sx={{width: ["100%", "100%", "50%"]}}>
            <Img fluid={data.logo.childImageSharp.fluid} sx={{width: "150px"}} />
            <Styled.h1>MAINTAIN YOUR GAINS WHILE STAYING HOME.</Styled.h1>
            <Styled.p>Join our training family with at-home workout programs that get you results and maintain muscle in as little as 30 minutes. </Styled.p>
            <div sx={{mt: 5}}>
              <ToggleSignUp cta="Get Started" />
            </div>
          </div>
          <div sx={{width: ["100%", "100%", "50%"], py: 5}}>
            <div sx={{width: "300px", mx: "auto"}}>
              <video sx={{width: "100%"}} muted playsInline autoPlay loop>
                <source src="https://cdn.sanity.io/files/mokttt7p/production/3e8dcf953546e314b54e87525f4fb3edfb05c123.mp4" />
                Your browser doesn't support video
              </video>
            </div>
          </div>
        </Flex>
      </Container>
      <Box sx={{width: "100%", backgroundColor: "#f6f6f4", px:5, py: 3}}>
        <Container sx={{width: "100%", maxWidth: "max"}}>
          <Styled.h4 sx={{textAlign: "center"}}>Programs engineered by experts featured in:</Styled.h4>
          <Flex sx={{justifyContent: ["center","center","space-between"], flexWrap: "wrap", alignItems: "center" }}>
            <span><img src={mensfitness} width="100px" sx={{px: [3,2,0]}}/></span>
            <span><img src={mensfitness} width="100px" sx={{px: [3,2,0]}}/></span>
            <span><img src={mensfitness} width="100px" sx={{px: [3,2,0]}}/></span>
            <span><img src={mensfitness} width="100px" sx={{display: ["none","none","block"]}}/></span>
            <span><img src={mensfitness} width="100px" sx={{display: ["none","none","block"]}}/></span>
          </Flex>
        </Container>
      </Box>
      <Box sx={{width: "100%", backgroundColor: "dark", px: 5, py:3}}>
        <Container sx={{width: "100%", maxWidth: "max"}}>
       
        <Flex sx={{justifyContent: "center", flexWrap: "wrap", alignItems: "center"}}>
          <div sx={{width: "200px", mr: [0,0,5], my: 5}}>
            <Img fluid={data.stevecook.childImageSharp.fluid} />
          </div>
          <div sx={{width: ["100%", "100%", "50%"]}}>
            <Styled.p sx={{color: "light"}}>"Working out from home can be just as intense and just as life altering as working out at a gym. Drop the excuses and let's get after
            it with Fitness Culture's in-home workout."</Styled.p>
            <Styled.h2 sx={{color: "light", textAlign: ["center", "center", "left"]}}>Steve Cook</Styled.h2>
            <Styled.h4 sx={{color: "light", textAlign: ["center", "center", "left"], mt: 0}}>Fitness Culture, Co-Founder</Styled.h4>
          </div>
        </Flex>
        </Container>
      </Box>
      <Box sx={{width: "100%", backgroundColor: "gray", px:5}}>
        <Container sx={{width: "100%", maxWidth: "max", py: 6}}>
        <Flex sx={{justifyContent: "center", flexWrap: "wrap", alignItems: "center"}}>
          <div sx={{display: ['none','none','block'],width: ["100%", "100%", "50%"], py: 5}}>
            <div sx={{width: "100%", maxWidth: "700px", mx: "auto"}}>
              <Img fluid={data.workout.childImageSharp.fluid} />
            </div>
          </div>
          <div sx={{width: ["100%", "100%", "50%"]}}>
            <Styled.h2>The Ultimate At-home Program.</Styled.h2>
            <Styled.p>Join our training family with at-home workout programs that get you results and maintain muscle in as little as 30 minutes. </Styled.p>
            <Grid columns={[1,1,2]} gap={[3,3,5]}>
              <div>
                <Styled.h3 sx={{borderBottom: "1px solid rgba(33,33,35,0.5)", pb: 4}}>Maintain Muscle</Styled.h3>
                <Styled.p>At-home programs for maintaining muscle mass. Minimal, to no equipment, with maximum results.</Styled.p>
              </div>
              <div>
                <Styled.h3 sx={{borderBottom: "1px solid rgba(33,33,35,0.5)", pb: 4}}>Improve Endurance</Styled.h3>
                <Styled.p>Conditioning designed to help you push through every set and rep while challenging you with variety.</Styled.p>
              </div>
              <div>
                <Styled.h3 sx={{borderBottom: "1px solid rgba(33,33,35,0.5)", pb: 4}}>Enhance Cardio</Styled.h3>
                <Styled.p>Bend don't break. Our routines will update constantly to help you stay mobile and prevent injury.</Styled.p>
              </div>
              <div>
                <Styled.h3 sx={{borderBottom: "1px solid rgba(33,33,35,0.5)", pb: 4}}>Maximize Mobility</Styled.h3>
                <Styled.p>Maximize your results, by optimizing your nutrition with our built in macro calculator and meal planner.</Styled.p>
              </div>
            </Grid>
            <div sx={{mt: 5}}>
              <ToggleSignUp cta="Get Started" />
            </div>
          </div>

        </Flex>
        </Container>
      </Box>
      <Box sx={{backgroundColor: "light", px: 5}}>
        <Container sx={{width: "100%", maxWidth: "max", py: 6, mx: "auto"}}>
          <Styled.h2 sx={{textAlign: "center"}}>Real Humans. Real Results.</Styled.h2>
          <Styled.h3 sx={{textAlign: "center", fontWeight: "body", color: "#73868D"}}>Here are just a few</Styled.h3>
          <Flex sx={{justifyContent: "center", flexWrap: "wrap", height: "100%"}}>
            <Flex sx={{width: ['100%', '100%', '30%'], p: 5, mx: 3,  mt: 4, flexDirection: "column", justifyContent: "space-between", backgroundColor: "#ffffff", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);"}}>
              <Styled.p>"[The programming] leaves ZERO to chance and covers every aspect of a healthy lifestyle regardless of goals. It's all housed in a single app so it cuts down on the time spent guessing if what you're doing is right, which creates more time to focus on the task at hand"</Styled.p>
              <Flex sx={{alignItems: "center"}}>
                <Img fluid={data.tyler.childImageSharp.fluid} sx={{width: "50px"}}/>
                <Flex sx={{ml: 2, flexDirection: "column", justifyContent: "flex-start"}}>
                  <Styled.h3 sx={{my: 0}}>Tyler Schuler</Styled.h3>
                  <Styled.h6 sx={{my: 0}}>Marion, Arkansas</Styled.h6>
                </Flex>
              </Flex>
            </Flex>
            <Flex sx={{width: ['100%', '100%', '30%'], p: 5, mx: 3,  mt: 4, flexDirection: "column", justifyContent: "space-between", backgroundColor: "#ffffff", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);"}}>
              <Styled.p>"Not too many [fitness plans] work so actually coming into Fitness Culture and expecting results, there is a lot of uncertainty there, but it worked out in the end. Using the app, it makes it real easy. It's just super simple."</Styled.p>
              <Flex sx={{alignItems: "center"}}>
                <Img fluid={data.brock.childImageSharp.fluid} sx={{width: "50px"}}/>
                <Flex sx={{ml: 2, flexDirection: "column", justifyContent: "flex-start"}}>
                  <Styled.h3 sx={{my: 0}}>Brock Goodwin</Styled.h3>
                  <Styled.h6 sx={{my: 0}}>St. George, Utah</Styled.h6>
                </Flex>
              </Flex>
            </Flex>
            <Flex sx={{width: ['100%', '100%', '30%'], p: 5, mx: 3,  mt: 4, flexDirection: "column", justifyContent: "space-between", backgroundColor: "#ffffff", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);"}}>
              <Styled.p>"It challenged me mentally and physically, but neither the macros or workouts were too difficult to achieve. It helped me get out of a plateau with my previous workout routine, and made me excited to get to the gym each night."</Styled.p>
              <Flex sx={{alignItems: "center"}}>
                <Img fluid={data.kat.childImageSharp.fluid} sx={{width: "50px"}}/>
                <Flex sx={{ml: 2, flexDirection: "column", justifyContent: "flex-start"}}>
                  <Styled.h3 sx={{my: 0}}>KAT TRINDER</Styled.h3>
                  <Styled.h6 sx={{my: 0}}>Canada</Styled.h6>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <div sx={{width: "100%", textAlign: "center", mt: 5}}>
            <ToggleSignUp cta="Try Now" />
          </div>
        </Container>  
      </Box>
      <Box sx={{backgroundColor: "dark", px: 5}}>
        <Container sx={{width: "100%", maxWidth: "max", py: 6, mx: "auto"}}>
          <Styled.h2 sx={{textAlign: "center", color: "white"}}>Real Humans. Real Results.</Styled.h2>
          <Styled.h3 sx={{textAlign: "center", fontWeight: "body", color: "#73868D"}}>Here are just a few</Styled.h3>
          <Flex sx={{justifyContent: "center", flexWrap: "wrap", height: "100%"}}>
            <Img fluid={data.bodyweight.childImageSharp.fluid} sx={{width: "700px"}} />
          </Flex>
          <div sx={{width: "100%", textAlign: "center", mt: 5}}>
            <ToggleSignUp cta="Try Now" />
          </div>
        </Container>  
      </Box>
      <Comparison />
      <Box sx={{width: "100%", backgroundColor: "gray", px:5}}>
        <Container sx={{width: "100%", maxWidth: "max", py: 6}}>
        <Flex sx={{justifyContent: "center", flexWrap: "wrap", alignItems: "center"}}>
          <div sx={{width: ["100%", "100%", "50%"]}}>
            <Styled.h2>The Ultimate Fitness App.</Styled.h2>
            <Styled.p>Join our training family with at-home workout programs that get you results and maintain muscle in as little as 30 minutes. </Styled.p>
            <Grid columns={[1,1,2]} gap={[3,3,5]}>
              <div>
                <Styled.h3 sx={{borderBottom: "1px solid rgba(33,33,35,0.5)", pb: 4}}>Tailored Program</Styled.h3>
                <Styled.p>At-home programs for maintaining muscle mass. Minimal, to no equipment, with maximum results.</Styled.p>
              </div>
              <div>
                <Styled.h3 sx={{borderBottom: "1px solid rgba(33,33,35,0.5)", pb: 4}}>Creative Conditioning</Styled.h3>
                <Styled.p>Conditioning designed to help you push through every set and rep while challenging you with variety.</Styled.p>
              </div>
              <div>
                <Styled.h3 sx={{borderBottom: "1px solid rgba(33,33,35,0.5)", pb: 4}}>Mobility Routines</Styled.h3>
                <Styled.p>Bend don't break. Our routines will update constantly to help you stay mobile and prevent injury.</Styled.p>
              </div>
              <div>
                <Styled.h3 sx={{borderBottom: "1px solid rgba(33,33,35,0.5)", pb: 4}}>Macros and Nutrition</Styled.h3>
                <Styled.p>Maximize your results, by optimizing your nutrition with our built in macro calculator and meal planner.</Styled.p>
              </div>
            </Grid>
            <div sx={{mt: 5}}>
              <ToggleSignUp cta="Get Started" />
            </div>
          </div>
          <div sx={{display: ['none','none','block'],width: ["100%", "100%", "50%"], py: 5}}>
            <div sx={{width: "100%", maxWidth: "700px", mx: "auto"}}>
              <Img fluid={data.phone.childImageSharp.fluid} />
            </div>
          </div>
        </Flex>
        </Container>
      </Box>
      <Box sx={{backgroundColor: "light", px: 5}}>
        <Container sx={{width: "100%", maxWidth: "max", py: 6, mx: "auto"}}>
          <Styled.h2 sx={{textAlign: "center", color: "dark"}}>Pricing.</Styled.h2>
          <Styled.h3 sx={{textAlign: "center", fontWeight: "body", color: "#73868D"}}>Get Started for less than a dollar a day</Styled.h3>
          <Flex sx={{flexDirection: "column", justifyContent: "center", width: "100%", textAlign: "center"}}>
            <Styled.h1 sx={{mt: 5, mb: 2}}>$19</Styled.h1>
            <Styled.h6 sx={{mb: 6, my: 0}}>PER MONTH</Styled.h6>
          </Flex>
          <div sx={{width: "100%", textAlign: "center", mt: 5}}>
            <ToggleSignUp cta="Try Now" />
          </div>
        </Container>  
      </Box>
      <Box sx={{backgroundColor: "dark", px: 5}}>
        <Container sx={{width: "100%", maxWidth: "max", py: 6, mx: "auto"}}>
          <Styled.h2 sx={{textAlign: "center", color: "white"}}>Stress-free Signup.</Styled.h2>
          <Styled.h3 sx={{textAlign: "center", fontWeight: "body", color: "#73868D"}}>Get Started for less than a dollar a day</Styled.h3>
          <Flex sx={{flexWrap: "row", mt: 6, mx: "auto", maxWidth: "large", justifyContent: "space-between", width: "100%", textAlign: "center"}}>
            <div>
              <Styled.h3 sx={{color: "white", textAlign: "center"}}>Cancel Anytime</Styled.h3>
            </div>
            <div>
              <Styled.h3 sx={{color: "white", textAlign: "center"}}>No Contracts</Styled.h3>
            </div>
            <div>
              <Styled.h3 sx={{color: "white", textAlign: "center"}}>Pay Monthly</Styled.h3>
            </div>
          </Flex>
        </Container>  
      </Box>
    </Layout>

  )
}


export default IndexPage
