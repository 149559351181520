/** @jsx jsx */
import { jsx, Container, Styled, Grid, Flex, Box} from 'theme-ui'
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import Swiper from 'react-id-swiper';

import Checkmark from "../../images/checkmark.png"
import Nope from "../../images/nope.png"

import "../../styles/swiper.css"


export const Comparison = () => {


  const options = [
    {
      width: "125px",
      price: "$1",
      oil: `${Checkmark}`,
      theanine: `${Checkmark}`,
      vitamins: `${Checkmark}`,
      bundle: `${Checkmark}`,
      delivered: `${Checkmark}`
    },
    {
      width: "50px",
      price: "$55",
      oil: `${Nope}`,
      theanine: `${Nope}`,
      vitamins: `${Nope}`,
      bundle: `${Nope}`,
      delivered: `${Nope}`

    },
  ]


  const data = useStaticQuery(graphql`
    query {  
      checkmark: file(relativePath: {eq: "checkmark.png"}){
        childImageSharp {
          fixed(width: 15, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        } 
      } 
      nope: file(relativePath: {eq: "nope.png"}){
        childImageSharp {
          fixed(width: 15, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        } 
      } 
    } 
  `)
  

  const params = {
    pagination: {
      el: '.swiper-pagination',
      slidesPerView: 1,
      clickable: true,
      loop: true,
    }
  }



  return (
    <Box sx={{backgroundColor: "light", px: 5}}>
      <Container sx={{width: "100%", maxWidth: "large", py: 6}}>
        <Styled.h2 sx={{textAlign: "center"}}>FITNESS CULTURE VS PERSONAL TRAINER</Styled.h2>
        <Grid columns={[3]} gap={0} sx={{display: ["none","none","grid"], py: 4}}>
          <div sx={{borderBottom: "1px solid black"}}></div>
          <Flex sx={{p: 5, borderLeft: "1px solid black", borderBottom: "1px solid black", borderTop: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "blue"}}></Flex>
          <Flex sx={{p: 5, borderLeft: "1px solid black", borderBottom: "1px solid black", borderTop: "1px solid black", borderRight: "1px solid black", justifyContent: "center", alignItems: "center"}}></Flex>
      
          <div sx={{width: "100%", minWidth: ["200px", "200px","400px"], fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>Price per Workout</div>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", fontWeight: "heading", backgroundColor: "blue"}}>$1</Flex>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", borderRight: "1px solid black", justifyContent: "center", alignItems: "center", fontWeight: "heading",}}>$55*</Flex>
      
          <div sx={{width: "100%", minWidth: ["200px", "200px","400px"], fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>In-Home and At-Gym</div>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "blue"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", borderRight: "1px solid black", justifyContent: "center", alignItems: "center"}}><Img fixed={data.nope.childImageSharp.fixed}/></Flex>
      
          <div sx={{width: "100%", minWidth: ["200px", "200px","400px"], fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>24/7 Availability</div>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "blue"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", borderRight: "1px solid black", justifyContent: "center", alignItems: "center"}}><Img fixed={data.nope.childImageSharp.fixed}/></Flex>
      
          <div sx={{width: "100%", minWidth: ["200px", "200px","400px"], fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>Thousands with Results</div>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "blue"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", borderRight: "1px solid black", justifyContent: "center", alignItems: "center"}}><Img fixed={data.nope.childImageSharp.fixed}/></Flex>
      
          <div sx={{width: "100%", minWidth: ["200px", "200px","400px"], fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>College Degrees</div>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "blue"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", borderRight: "1px solid black", justifyContent: "center", alignItems: "center"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>
      
          <div sx={{width: "100%", minWidth: ["200px", "200px","400px"], fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>Macros + Meal Builder</div>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "blue"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", borderRight: "1px solid black", justifyContent: "center", alignItems: "center"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>

          <div sx={{width: "100%", minWidth: ["200px", "200px","400px"], fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>Support</div>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "blue"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>
          <Flex sx={{p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black", borderRight: "1px solid black", justifyContent: "center", alignItems: "center"}}><Img fixed={data.checkmark.childImageSharp.fixed}/></Flex>
        </Grid>
        <div sx={{display: ["block","block","none"], py: 4}}>
          <Swiper {...params}>
            {options.map((o,i) => (
              <div key={i} sx={{pb: 4}}>
                <Flex style={{gridColumn: "1/-1"}} sx={{justifyContent: "center", alignItems: "center", border: "1px solid black", width: "100%", height: "200px"}}>
                  <img src={o.image} width={o.width} />
                </Flex>
                <Grid columns={2} gap={0} sx={{gridAutoRows: "1fr"}}>
                    <div sx={{width: "100%", fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>Price per Cup</div>
                    <Flex sx={{p: 3, borderRight: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", fontWeight: "heading", backgroundColor: "#EFDEC7"}}>{o.price}</Flex>

                    <div sx={{width: "100%", fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>MCT Oil</div>
                    <Flex sx={{p: 3, borderRight: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "#EFDEC7"}}><img src={o.oil} height="15px" /></Flex>

                    <div sx={{width: "100%", fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>L-Theanine</div>
                    <Flex sx={{p: 3, borderRight: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "#EFDEC7"}}><img src={o.theanine} height="15px" /></Flex>

                    <div sx={{width: "100%", fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>Vitamins E, D3 + B12</div>
                    <Flex sx={{p: 3, borderRight: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "#EFDEC7"}}><img src={o.vitamins} height="15px" /></Flex>

                    <div sx={{width: "100%", fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>Bundle Option</div>
                    <Flex sx={{p: 3, borderRight: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "#EFDEC7"}}><img src={o.bundle} height="15px" /></Flex>

                    <div sx={{width: "100%", fontWeight: "heading", p: 3, borderLeft: "1px solid black", borderBottom: "1px solid black"}}>Delivered Straight to Your Door</div>
                    <Flex sx={{p: 3, borderRight: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black", justifyContent: "center", alignItems: "center", backgroundColor: "#EFDEC7"}}><img src={o.delivered} height="15px" /></Flex>
                </Grid>
              </div>

            ))}

          </Swiper>

        </div>
      </Container>  
    </Box>
)}